import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import MainTypoSVG from '../resources/main_typo.svg';

const MainPageVideo = ({ className = 'w-full h-full object-cover', posterImage = undefined }) => {
  return (
    <video
      poster={posterImage}
      autoPlay
      muted
      loop
      playsInline
      disablePictureInPicture
      disableRemotePlayback
      className={className}
    >
      <source src="/wlpo_main_mov.mp4" type="video/mp4" />
    </video>
  );
};

export default function IndexPage(props) {
  return (
    <Layout>
      <SEO title={null} titleTemplate="" />
      <div className="fixed inset-0 z-[-1] opacity-80">
        <MainPageVideo />
      </div>
      <div className="absolute inset-0 flex justify-center items-center mt-20 w-full sm:p-8 p-4">
        <MainTypoSVG className="w-full" />
        {/* <ContentPlaceholder className="w-full h-auto mx-auto p-16" /> */}
      </div>
    </Layout>
  );
}
